import { render, staticRenderFns } from "./Reciept.vue?vue&type=template&id=20ca9e98"
import script from "./Reciept.vue?vue&type=script&lang=js"
export * from "./Reciept.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports